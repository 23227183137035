import React, { useState, useCallback, useMemo } from 'react';
import clippyGif from '../assets/imgs/clippy.gif';
import clippyWebp from '../assets/imgs/clippy.webp';

const CLIPPY_MESSAGES = [
  "Hey there! 👋 Double-click those icons... you know, like it's Windows 95! 🖱️",
  "Pro tip: These icons aren't just for decoration. Double-click them! Mind-blowing, right? 🤯",
  "Psst... Want to know a secret? These icons open when you *double-click* them! 🤫",
  "Hey! Those icons are getting bored. Why not double-click one? 😴"
];

const ClippyAssistant = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  const randomMessage = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * CLIPPY_MESSAGES.length);
    return CLIPPY_MESSAGES[randomIndex];
  }, []);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    // Remove component after animation completes
    setTimeout(() => {
      setIsVisible(false);
    }, 500); // Match the animation duration
  }, []);

  if (!isVisible) return null;

  return (
    <div className={`clippy-assistant ${isClosing ? 'closing' : ''}`}>
      <div className="speech-bubble">
        <button className="close-button" onClick={handleClose} aria-label="Close assistant" />
        {randomMessage}
      </div>
      <picture className="clippy-image">
        <source srcSet={clippyWebp} type="image/webp" />
        <img src={clippyGif} alt="Clippy Assistant" />
      </picture>
    </div>
  );
};

export default ClippyAssistant;
