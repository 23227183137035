import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { TfiNewWindow } from "react-icons/tfi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation, Pagination } from "swiper";
import { BiLoader } from "react-icons/bi";

const SingleProjectWindow = ({
  isItActive,
  programWindowHeight,
  singleProjectData,
  closeProject,
}) => {
  const [preloaded, setPreloaded] = useState(false);

  const preloadImages = () => {
    const promises = singleProjectData.images.map((image) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = image.original;
        img.onload = resolve;
        img.onerror = resolve;
      });
    });
    Promise.all(promises).then(() => setPreloaded(true));
  };

  useEffect(() => {
    if (singleProjectData.images) {
      preloadImages();
    }
  }, [singleProjectData.images]);

  return (
    <div
      className={
        isItActive ? "single-project-window active" : "single-project-window"
      }
      style={{
        height: `calc(100% - ${programWindowHeight}px)`,
      }}
    >
      <div className="single-project-content">
        <div className="project-images">
          {preloaded ? (
            <Swiper
              zoom={true}
              navigation={true}
              pagination={{ clickable: true }}
              autoHeight={false}
              modules={[Zoom, Navigation, Pagination]}
              className={
                singleProjectData.title
                  .toLowerCase()
                  .replace(/ /g, "-")
                  .replace(/[^a-zA-Z0-9-]/g, "") + "-swiper"
              }
            >
              {singleProjectData.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-zoom-container">
                    <img
                      src={image.original}
                      alt={`${singleProjectData.title}-${index + 1}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="loading-images">
              <BiLoader />
            </div>
          )}
        </div>
        <div className="content-wrapper">
          <h2>{singleProjectData.title}</h2>
          <p>{singleProjectData.description}</p>
          <ul>
            {singleProjectData.tech_used &&
              singleProjectData.tech_used.map((tech, index) => (
                <li key={index}>{tech}</li>
              ))}
          </ul>
          <div className="project-links">
            <div className="close-project" onClick={closeProject}>
              Close Project <FaTimes />
            </div>
            {singleProjectData.link && (
              <button
                rel="noreferrer"
                className="btn btn-blank"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(singleProjectData.link, "_blank");
                }}
              >
                Visit Site <TfiNewWindow />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProjectWindow;
